import React from "react";
import { Footer } from "../components/Footer";
import { NavBar } from "../components/NavBar";
import moment from "moment";

export const Blog = ({ blog }) => {
  console.log(blog);
  return (
    <div className="w-screen min-h-screen bg-gray-50">
      <NavBar />
      <div className="flex flex-col flex-1 px-5 pt-10 pb-10 max-w-3xl m-auto bg-white border rounded-lg mb-20">
        <section id="header" className="flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <div className="rounded-full overflow-hidden w-16">
              <img src={require("../assets/image/blog/author-vishwa.png")} alt="avatar" />
            </div>
            <div className="ml-6 text-left">
              <h4>
                {blog.author?.firstName} {blog.author?.lastName}
              </h4>
              <p className="text-sm text-gray-600">
                {moment.unix(blog?.publishDate).format("MMM Do")} · {blog.averageReadTime} minute read
              </p>
            </div>
          </div>
          <div>
            <button
              onClick={() => (window.location = "/download")}
              className="bg-blue-600 h-full w-full text-white p-3 rounded"
            >
              Download Acreetr
            </button>
          </div>
        </section>
        <section id="body">
          <div className="text-left my-3">
            <h1 className="text-2xl lg:text-3xl font-semibold my-5 lg:my-2 text-slate-900">{blog?.title}</h1>
          </div>
          <div className="my-10 w-full">
            {blog.image && (
              <img className="w-full h-full " src={require(`../assets/image/blog/${blog.image}`)} alt="blog" />
            )}
          </div>
          <div>
            <p className="text-left">Credit: {blog.imageCredit}</p>
          </div>
          <div className="text-left my-6 tracking-wider">
            <p className="font-sans">{blog.body()}</p>
          </div>
          <div className="border w-full p-3 rounded my-3">
            <h1 className="text-2xl lg:text-3xl font-semibold my-5 lg:my-2 text-slate-900">
              We can save you time and money
            </h1>
            <p className="text-gray-500 my-3">
              We are a team of developers and designers who are passionate about building the future of the real estate
              industry. Find out how we can help you save money and time. So you're free to spend it elsewhere.
            </p>
            <div>
              <button
                onClick={() => (window.location = "/download")}
                className="bg-blue-600 h-full w-full text-white p-3 rounded"
              >
                Find out more
              </button>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

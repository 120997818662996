import React from "react";

import AppStore from "../assets/brands/apple.svg";
import PlayStore from "../assets/brands/google.svg";

import logo from "../logo.svg";

export const Footer = () => {
  const Link = ({ text, href }) => {
    return (
      <a href={href ?? ""}>
        <p className="text-gray-500 text-sm mt-2 hover:text-blue-600 hover:opacity-90">{text}</p>
      </a>
    );
  };
  return (
    <section className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-center align-middle pb-6">
      <div className="flex flex-col lg:flex-row items-center justify-between">
        <div className="flex flex-row mt-6">
          <div
            onClick={() => (window.location = "/download")}
            class="h-12 flex items-center justify-center rounded bg-black px-2 w-40 mr-3 cursor-pointer hover:bg-slate-900"
          >
            <img src={AppStore} alt="AppStore" />
          </div>
          <div
            onClick={() => (window.location = "/download")}
            class="h-12 flex items-center justify-center rounded bg-black px-2 w-40 cursor-pointer hover:bg-slate-900"
          >
            <img src={PlayStore} alt="AppStore" />
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-between items-start text-left mt-10 pb-6">
        <div className="mb-8 lg:mb-0">
          <div>
            <a href="">
              <img src={logo} alt="logo" className="h-12" />
            </a>
            <p className="text-gray-500">The Ultimate toolbox for landlords.</p>
          </div>
        </div>
        <div className="mb-8 lg:mb-0">
          <h4 className="text-blue-600 font-semibold text-lg">Product</h4>
          <Link text="Features" href={"/#features"} />
          <Link text="Pricing" href="/#pricing" />
          <Link text="Download" href={"/download"} />
        </div>
        <div className="mb-8 lg:mb-0">
          <h4 className="text-blue-600 font-semibold text-lg">Resources</h4>
          <Link text="FAQs" href="faq" />
          <Link text="Terms & Conditions" href={"/terms-and-conditions"} />
          <Link text="Privacy Policy" href={"/privacy-policy"} />
        </div>
        <div>
          <h4 className="text-blue-600 font-semibold text-lg">Connect</h4>
          <p className="text-gray-500 text-sm mt-2">hello@acreetr.com</p>
          <div className="flex flex-row justify-start mt-1 text-gray-500 ">
            <div
              className="mr-3 hover:text-blue-600"
              onClick={() => (window.location = "https://linkedin.com/company/acreetr")}
            >
              <ion-icon name="logo-linkedin"></ion-icon>
            </div>
            <div className="hover:text-blue-600" onClick={() => (window.location = "https://instagram.com/acreetr")}>
              <ion-icon name="logo-instagram"></ion-icon>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div className="border-t pt-6 flex flex-col lg:flex-row justify-between items-center text-gray-500 text-sm">
        <p>Copyright © 2022, Acreetr Ltd, no. 13379109.</p>
        <p>86-90 Paul Street London England EC2A 4NE</p>
      </div>
    </section>
  );
};

import React from "react";
import logo from "../logo.svg";
import { Menu, Transition } from "@headlessui/react";

export const NavBar = () => {
  return (
    <header class="p-3 mb-10 border-b sticky xs:sticky top-0 bg-[rgba(255,255,255,0.9)] w-full z-50 backdrop-blur-3xl">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <nav class="relative z-50 flex justify-between">
          <div class="flex items-center md:gap-x-12">
            <span aria-label="Home">
              <img src={logo} className="lg:h-12 h-8" alt="logo" />
            </span>
            <div class="hidden md:flex md:gap-x-6">
              <a
                class="inline-block rounded-lg py-1 px-2 text-md text-slate-700 hover:bg-slate-100 hover:text-slate-900"
                href="/"
              >
                Home
              </a>
              <a
                class="inline-block rounded-lg py-1 px-2 text-md text-slate-700 hover:bg-slate-100 hover:text-slate-900"
                href="/#features"
              >
                Features
              </a>
              <a
                class="inline-block rounded-lg py-1 px-2 text-md text-slate-700 hover:bg-slate-100 hover:text-slate-900"
                href="/#pricing"
              >
                Pricing
              </a>
            </div>
          </div>
          <div class="flex items-center gap-x-5 md:gap-x-8">
            <div class="hidden md:block">
              <a
                class="inline-block rounded-lg py-1 px-2 text-md text-slate-700 hover:bg-slate-100 hover:text-slate-900"
                href="/login"
              >
                Contact us
              </a>
            </div>
            <a
              class="group inline-flex items-center justify-center rounded py-2 px-4 text-md font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600"
              href="/download"
            >
              <span>
                Download <span class="hidden lg:inline">app now</span>
              </span>
            </a>
            <div class="-mr-1 md:hidden">
              <div>
                <HamburgerMenu />
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

const HamburgerMenu = () => {
  return (
    <div className="">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="relative z-10 flex h-8 w-8 items-center justify-center [:not(:focus-visible)]:focus:outline-none">
            <svg
              aria-hidden="true"
              class="h-3.5 w-3.5 overflow-visible stroke-slate-700"
              fill="none"
              stroke-width="2"
              stroke-linecap="round"
            >
              <path d="M0 1H14M0 7H14M0 13H14" class="origin-center transition"></path>
              <path d="M2 2L12 12M12 2L2 12" class="origin-center transition scale-90 opacity-0"></path>
            </svg>
          </Menu.Button>
        </div>
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="px-1 py-2 absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => (window.location = "/")}
                  className={`${
                    active ? "bg-blue-600 text-white" : "text-gray-900"
                  } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                >
                  Home
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => (window.location = "/#features")}
                  className={`${
                    active ? "bg-blue-600 text-white" : "text-gray-900"
                  } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                >
                  Features
                </button>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => (window.location = "/#pricing")}
                  className={`${
                    active ? "bg-blue-600 text-white" : "text-gray-900"
                  } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                >
                  Pricing
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

import React from "react";

export const Blog2 = {
  title: "How To Manage Rental Properties: A Simple Step-By-Step Guide",
  image: "2.jpg",
  imageCredit: "",
  averageReadTime: 9,
  publishDate: 1630593148,
  author: {
    firstName: "Vishwa",
    lastName: "Jeyam",
  },
  body: () => {
    return (
      <div>
        "Book description: In June, 1954, eighteen-year-old Emmett Watson is driven home to Nebraska by the warden of
        the juvenile work farm where he has just served fifteen months for involuntary manslaughter. His mother long
        gone, his father recently deceased, and the family farm foreclosed upon by the bank, Emmett's intention is to
        pick up his eight-year-old brother, Billy, and head to California where they can start their lives anew. But
        when the warden drives away, Emmett discovers that two friends from the work farm have hidden themselves in the
        trunk of the warden's car. Together, they have hatched an altogether different plan for Emmett's future, one
        that will take them all on a fateful journey in the opposite direction—to the City of New York."
        <br />
        <br />
        "Book description: In June, 1954, eighteen-year-old Emmett Watson is driven home to Nebraska by the warden of
        the juvenile work farm where he has just served fifteen months for involuntary manslaughter. His mother long
        gone, his father recently deceased, and the family farm foreclosed upon by the bank, Emmett's intention is to
        pick up his eight-year-old brother, Billy, and head to California where they can start their lives anew. But
        when the warden drives away, Emmett discovers that two friends from the work farm have hidden themselves in the
        trunk of the warden's car. Together, they have hatched an altogether different plan for Emmett's future, one
        that will take them all on a fateful journey in the opposite direction—to the City of New York."
        <br />
        <br />
        "Book description: In June, 1954, eighteen-year-old Emmett Watson is driven home to Nebraska by the warden of
        the juvenile work farm where he has just served fifteen months for involuntary manslaughter. His mother long
        gone, his father recently deceased, and the family farm foreclosed upon by the bank, Emmett's intention is to
        pick up his eight-year-old brother, Billy, and head to California where they can start their lives anew. But
        when the warden drives away, Emmett discovers that two friends from the work farm have hidden themselves in the
        trunk of the warden's car. Together, they have hatched an altogether different plan for Emmett's future, one
        that will take them all on a fateful journey in the opposite direction—to the City of New York." <br />
        <br />
        "Book description: In June, 1954, eighteen-year-old Emmett Watson is driven home to Nebraska by the warden of
        the juvenile work farm where he has just served fifteen months for involuntary manslaughter. His mother long
        gone, his father recently deceased, and the family farm foreclosed upon by the bank, Emmett's intention is to
        pick up his eight-year-old brother, Billy, and head to California where they can start their lives anew. But
        when the warden drives away, Emmett discovers that two friends from the work farm have hidden themselves in the
        trunk of the warden's car. Together, they have hatched an altogether different plan for Emmett's future, one
        that will take them all on a fateful journey in the opposite direction—to the City of New York."
        <br />
        <br />
        "Book description: In June, 1954, eighteen-year-old Emmett Watson is driven home to Nebraska by the warden of
        the juvenile work farm where he has just served fifteen months for involuntary manslaughter. His mother long
        gone, his father recently deceased, and the family farm foreclosed upon by the bank, Emmett's intention is to
        pick up his eight-year-old brother, Billy, and head to California where they can start their lives anew. But
        when the warden drives away, Emmett discovers that two friends from the work farm have hidden themselves in the
        trunk of the warden's car. Together, they have hatched an altogether different plan for Emmett's future, one
        that will take them all on a fateful journey in the opposite direction—to the City of New York."
        <br />
        <br />
        "Book description: In June, 1954, eighteen-year-old Emmett Watson is driven home to Nebraska by the warden of
        the juvenile work farm where he has just served fifteen months for involuntary manslaughter. His mother long
        gone, his father recently deceased, and the family farm foreclosed upon by the bank, Emmett's intention is to
        pick up his eight-year-old brother, Billy, and head to California where they can start their lives anew. But
        when the warden drives away, Emmett discovers that two friends from the work farm have hidden themselves in the
        trunk of the warden's car. Together, they have hatched an altogether different plan for Emmett's future, one
        that will take them all on a fateful journey in the opposite direction—to the City of New York."
      </div>
    );
  },
};

import React from "react";
import { Footer } from "../components/Footer";
import { NavBar } from "../components/NavBar";
import { NewsLetter } from "../components/Newsletter";

const FAQ = [
  {
    title: "What is Acreetr?",
    content:
      "Simply put, Acreetr is a hub for landlords. We give you features such as, live chat, rental collection, document storage and much more for a single price of £29.99/month. To help you understand, here is an example: A tenant has a broken boiler, they notify you through the app, once a repair has been arranged either by you or us, you can collect payment from the tenant via Acreetr, store reciepts and documents which you can access instantly at a later date if needed. We also have other tools that can help you save time. Inventory checks, document templates and much more! ",
  },
  {
    title: "How Acreetr works?",
    content:
      "You download the app and create an account, we issue you a 'landlord code'. Your tenant downloads the app and creates an account, they enter the 'landlord code' that you give to them. They are now in your group. You can now manage them, give them rent reminders, live chat, complete inventory checks and more!",
  },
  {
    title: "Why is Acreetr better than the traditional route?",
    content:
      "Highstreet property managers charge you enormous amounts of money for their services, completely isolating you from your own property. Although some prefer this automated system, we're commited to providing you a cheap, modern alterantive that gives you, the landlord, complete control & flexibility over your rental property whilst still having time to relax.",
  },
  {
    title: "Is there a free trial available?",
    content:
      "Yes - All new users get one month free. We provide you with tools that make managing property easier. Please visit our pricing page for more information.",
  },
  {
    title: "Can I cancel my subscription?",
    content:
      "We initially, do not ask you for any payment information as the first month is completely free. After this we charge you £29.99/month which you can cancel at any point in the future with no further obligation.",
  },
];

export const FAQScreen = () => {
  return (
    <div className="bg-gray-50 w-screen min-h-screen">
      <NavBar />
      <section className="flex flex-col items-center justify-center mb-10">
        <h1 className="mb-10 text-3xl lg:text-5xl font-semibold text-slate-900">Frequently Asked Questions</h1>
        <div className="w-11/12 lg:w-1/2">
          {FAQ.map((faq) => (
            <FaqAccordion {...faq} />
          ))}
          <div className="bg-white p-3 shadow w-full mt-10 flex flex-col items-center">
            <div className="text-blue-600 text-6xl flex items-center justify-cente mb-3">
              <ion-icon name="help-buoy-outline"></ion-icon>
            </div>
            <h2 className="text-2xl lg:text-3xl font-semibold text-slate-900">Still have questions?</h2>
            <p className="text-gray-500 w-3/4">
              Enter your email below and someone from our sales team will answer all your questions aswell as offer you
              a free month.
            </p>
            <div className="w-full">
              <NewsLetter outline flexed />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

const FaqAccordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div
      className="mb-5 bg-white shadow p-3 rounded  text-left cursor-pointer flex flex-col"
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className={`flex flex-row justify-between items-center ${isOpen && " mb-3"}`}>
        <h3 className="text-xl text-slate-900">{title}</h3>
        <div className="text-blue-600 text-3xl flex items-center justify-center">
          {isOpen ? <ion-icon name={"remove-circle-outline"} /> : <ion-icon name={"add-circle-outline"} />}
        </div>
      </div>
      {isOpen && <div className="border-t pt-3 text-gray-500">{content}</div>}
    </div>
  );
};

import React from "react";

import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

export const NewsLetter = ({ outline, flexed }) => {
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const reCaptchaRef = React.useRef();
  const handleSubmit = async (e) => {
    setLoading(true);
    setError("");
    setSuccess("");

    e.preventDefault();
    try {
      const token = await reCaptchaRef.current.executeAsync();
      console.log(token);
      var bodyFormData = new FormData();
      bodyFormData.append("EMAIL", email);
      bodyFormData.append("g-recaptcha-response", token);
      bodyFormData.append("email_address_check", "");
      bodyFormData.append("locale", "en");

      await axios({
        method: "POST",
        url: "https://4003a9a9.sibforms.com/serve/MUIEAPtD9XNyRPv3kLLCMiURt2fkR1TCrdfb7sDqeLMYqIR1ZOHlZ8qjafJlET6ceED1bInkWquOpmUqlLiX-Ta97hPC_koJ5Jf1X91LBtCXRhcVRwc1NdaX4H_25FsqXc_d3vJKKgH2pRHqAJNibes-ft_JWmkBgw3A0Wzwr1VKZjkB713FZ2kPO_ayaQ5BUZOvG878Yns9F7aT?isAjax=1",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      setSuccess(`Success - Someone will contact you soon`);
    } catch (e) {
      console.error(e);
      setError("Error - Please email hello@acreetr.com");
    } finally {
      setEmail("");
      setLoading(false);
      await reCaptchaRef.current.reset();
    }
  };
  return (
    <div className="flex flex-col">
      {success && !error && (
        <div className="w-full bg-green-200 rounded border border-green-600 text-green-600 text-left px-3 py-1 flex items-center">
          <div className="flex-1 flex flex-row items-center">
            <div className="pr-2 flex items-center">
              <ion-icon class="text-xl" name="checkmark-circle-outline"></ion-icon>
            </div>
            {success}
          </div>
          <div className="flex items-center" onClick={() => setSuccess("")}>
            <ion-icon class="text-xl" name="close-outline"></ion-icon>
          </div>
        </div>
      )}
      {error && (
        <div className="w-full bg-red-200 rounded border border-red-600 text-red-600 text-left px-3 py-1 flex items-center">
          <div className="flex-1 flex flex-row items-center">
            <div className="pr-2 flex items-center">
              <ion-icon class="text-xl" name="close-circle-outline"></ion-icon>
            </div>
            {error}
          </div>
          <div className="flex items-center" onClick={() => setError("")}>
            <ion-icon class="text-xl" name="close-outline"></ion-icon>
          </div>
        </div>
      )}
      <form
        id="sib-form"
        onSubmit={handleSubmit}
        className={`mt-5 flex flex-col md:flex-row w-full px-3 md:px-0 ${flexed ? "md:w-full" : "md:w-96"}`}
      >
        <input
          className={`p-3 rounded w-full ${outline ? "border" : ""}`}
          type="text"
          id="EMAIL"
          name="EMAIL"
          autoComplete="off"
          placeholder="Enter email"
          data-required="true"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <button
          form="sib-form"
          type="submit"
          disabled={loading}
          className="w-full md:w-32 px-3 py-3 md:py-1 mt-3 md:mt-0 md:ml-3 bg-blue-600 rounded hover:opacity-95 text-white text-sm"
        >
          Start Now
        </button>

        <div>
          <ReCAPTCHA
            badge="bottomleft"
            ref={reCaptchaRef}
            size="invisible"
            sitekey="6LeBDGQhAAAAAGlFi4YTJm1kkJrFeeqTs3hCvUDs"
          />
        </div>

        <input type="text" name="email_address_check" value="" class="hidden" />
        <input type="hidden" name="locale" value="en" />
      </form>
    </div>
  );
};

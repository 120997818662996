import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import { Blog } from "../screens/Blog";
import { DownloadScreen } from "../screens/DownloadScreen";

import { LandingScreen } from "../screens/LandingScreen";

import { Blog1 } from "../blogs/blog1";
import { Blog2 } from "../blogs/blog2";
import { Blog3 } from "../blogs/blog3";
import { FAQScreen } from "../screens/Faq";
import { PrivacyPolicy } from "../assets/legal/privacy-policy";
import { TermsAndConditions } from "../assets/legal/terms-and-conditions";

export const Router = () => {
  console.error(Blog1);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/faq" element={<FAQScreen />} />
        <Route path="/download" element={<DownloadScreen />} />
        <Route path="/blog/top-7-tenant-communication-tips-for-landlords" element={<Blog blog={Blog1} />} />
        <Route
          path="/blog/how-to-manage-rental-properties-a-simple-step-by-step-guide"
          element={<Blog blog={Blog2} />}
        />
        <Route path="/blog/12-ways-to-add-value-to-your-rental-property" element={<Blog blog={Blog3} />} />
        <Route path="/" element={<LandingScreen />} />
      </Routes>
    </BrowserRouter>
  );
};

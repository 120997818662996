import { Footer } from "../components/Footer";
import { NavBar } from "../components/NavBar";
import { NewsLetter } from "../components/Newsletter";

export const DownloadScreen = () => {
  return (
    <div className="bg-gray-50 w-screen min-h-screen h-full">
      <div className="bg-gray-50 w-screen min-h-screen">
        <NavBar />
        <section className="flex flex-col items-center justify-center mb-10 max-w-7xl m-auto pb-20">
          <h1 className="text-3xl lg:text-5xl font-semibold text-slate-900">The first month is free</h1>
          <div className="w-11/12 lg:w-1/2">
            <div className="bg-white p-3 shadow w-full mt-10 flex flex-col items-center">
              <div className="text-blue-600 text-6xl flex items-center justify-cente mb-3">
                <ion-icon name="help-buoy-outline"></ion-icon>
              </div>
              <h2 className="text-2xl lg:text-3xl font-semibold text-slate-900">Get in touch</h2>
              <p className="text-gray-500 w-3/4">
                We're currently working on a user by user baisis. Enter your email below and someone from our sales team
                will be in touch to help get you setup.
              </p>
              <div className="w-full">
                <NewsLetter outline flexed />
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
};

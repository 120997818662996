import React from "react";

import { NavBar } from "../components/NavBar";
import { Footer } from "../components/Footer";

import AppStore from "../assets/brands/apple.svg";
import PlayStore from "../assets/brands/google.svg";
import { NewsLetter } from "../components/Newsletter";

export const LandingScreen = () => {
  return (
    <div>
      <NavBar />
      <section class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8  pb-16 text-center  align-middle">
        <div className="flex flex-1 flex-col-reverse md:flex-row justify-between align-middle content-center">
          <div className="text-center md:text-left w-full justify-center flex flex-col">
            <h1 class="max-w-4xl font-display text-4xl lg:text-5xl font-medium tracking-tight text-slate-900 sm:text-7xl">
              The
              <span class="relative whitespace-nowrap text-blue-600">
                <svg
                  aria-hidden="true"
                  viewBox="0 0 418 42"
                  class="absolute top-1/4 left-0 h-[0.58em] w-full fill-blue-300/70"
                  preserveAspectRatio="none"
                >
                  <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
                </svg>
                <span class="relative"> ultimate </span>
              </span>
              toolbox for landlords.
            </h1>
            <p className="text-center md:text-left mt-6 max-w-2xl text-lg leading-relaxed tracking-tight text-slate-500 pr-0 lg:pr-16">
              Replace your property managers and manage your own payments, documents and tenants from your mobile phone.
            </p>
            <div className="flex flex-row mt-6">
              <div
                onClick={() => (window.location = "/download")}
                class="h-16 flex items-center justify-center rounded bg-black px-2 w-64 mr-3 cursor-pointer hover:bg-slate-900"
              >
                <img src={AppStore} alt="AppStore" />
              </div>
              <div
                onClick={() => (window.location = "/download")}
                class="h-16 flex items-center justify-center rounded bg-black px-2 w-64 cursor-pointer hover:bg-slate-900"
              >
                <img src={PlayStore} alt="AppStore" />
              </div>
            </div>
          </div>
          <div className="flex justify-center align-middle">
            <div className="w-6/12 md:w-10/12">
              <img src={require("../assets/mobile/landing.png")} alt="landing" className="w-full h-full" />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-slate-900">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8  pb-16 text-center align-middle py-10 sm:py-16 items-center flex flex-col">
          <h2 class="text-4xl lg:text-5xl font-semibold text-white">Your first month is free</h2>
          <p class="mt-3 text-lg text-slate-400">Try our tools for free for 30 days. No credit card required.</p>
          <div className="mt-5 flex flex-col md:flex-row w-96">
            <NewsLetter />
          </div>
        </div>
      </section>

      <section id="features">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8  pb-16 text-center flex flex-col justify-center items-center align-middle py-10 sm:py-20 ">
          <div className="flex flex-col px-20 md:px-0 md:flex-row justify-between ">
            <div className="px-5 my-5 md:my-0 w-96">
              <div className="text-blue-600 text-4xl">
                <ion-icon name="wallet-outline"></ion-icon>
              </div>
              <h3 className="text-2xl font-semibold mt-3">Payments</h3>
              <p className="text-slate-500 mt-3">
                Your tenants can pay you directly through the app and we don't take a fee. You get access to analytics
                and other statistics to maximize rental yield.
              </p>
            </div>
            <div className="px-5 my-5 md:my-0 w-96">
              <div className="text-blue-600 text-4xl">
                <ion-icon name="build-outline"></ion-icon>
              </div>
              <h3 className="text-2xl font-semibold mt-3">Management</h3>
              <p className="text-slate-500 mt-3">
                You have a direct line with your tenants via live chat. Boiler broken? No worries, you can source an
                engineer, arrange an appointement and recive payments all with the click of a button.
              </p>
            </div>
            <div className="px-5 my-5 md:my-0 w-96">
              <div className="text-blue-600 text-4xl">
                <ion-icon name="document-text-outline"></ion-icon>
              </div>
              <h3 className="text-2xl font-semibold mt-3">Documents</h3>
              <p className="text-slate-500 leading-relaxed mt-3">
                All actions you perform i.e. payments, appointments etc. are all stored on the cloud. We also store
                documents, scan them via your camera, and we will digitize them for you.
              </p>
            </div>
          </div>
          <div
            onClick={() => (window.location = "/faq")}
            className="w-64 mt-8 bg-blue-600 text-white px-3 py-2 rounded cursor-pointer hover:opacity-90"
          >
            <a>View more</a>
          </div>
        </div>
      </section>

      <section className="bg-gray-50 border-t">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8  pb-16 text-center align-middle py-20 sm:py-32">
          <h2 class="text-4xl lg:text-5xl font-semibold mb-5 lg:mb-2 text-slate-900">How Acreetr Works</h2>
          <p className="mb-16 text-center text-slate-600 text-lg max-w-xl mx-auto">
            Download the Acreetr app from the store, create an account and start managing your tenants.
          </p>
          <div className="flex flex-col lg:flex-row justify-between ">
            <div className="order-2 lg:order-1">
              <div className="justify-center flex flex-col items-center lg:items-end mt-12 lg:mt-0">
                <div className="bg-blue-600 h-20 w-20 flex items-center justify-center rounded-2xl text-4xl text-white font-bold">
                  1
                </div>
                <h2 className="text-xl font-semibold my-3">Create an account</h2>
                <p className="text-center lg:text-right text-slate-600">
                  Begin by downloading the Acreetr app from the Appstore or Playstore. Once installed, follow onscreen
                  instructions to setup an account.
                </p>
              </div>

              <div className="justify-center flex flex-col items-center lg:items-end mt-12 lg:mt-36">
                <div className="bg-blue-100 h-20 w-20 flex items-center justify-center rounded-2xl text-4xl text-blue-600 font-bold">
                  2
                </div>
                <h2 className="text-xl font-semibold my-3">Invite your tenants</h2>
                <p className="text-center lg:text-right text-slate-600">
                  Once your account has been created, you will be provided a unique 6-digit code that you can give to
                  your tenants.
                </p>
              </div>
            </div>
            <div className="ml-6 mr-7 justify-center items-center flex order-1 lg:order-2">
              <div className="w-11/12 max-w-96 relative">
                <img src={require("../assets/mobile/manage.png")} className="w-full h-full" />
              </div>
            </div>
            <div className="order-3 lg:order-3">
              <div className="justify-center flex flex-col items-center lg:items-start mt-12 lg:mt-0">
                <div className="bg-blue-100 h-20 w-20 flex items-center justify-center rounded-2xl text-4xl text-blue-600 font-bold">
                  3
                </div>
                <h2 className="text-xl font-semibold my-3">Tenants join your group</h2>
                <p className="text-center lg:text-left text-slate-600">
                  All of your tenants need to download and install the app. Once they register an account, they will be
                  asked for your 6 digit code. After entering they will be a apart of your group.
                </p>
              </div>

              <div className="justify-center flex flex-col items-center lg:items-start mt-12 lg:mt-32">
                <div className="bg-blue-100 h-20 w-20 flex items-center justify-center rounded-2xl text-4xl text-blue-600 font-bold">
                  4
                </div>
                <h2 className="text-xl font-semibold my-3">Let's start managing</h2>
                <p className="text-center lg:text-left text-slate-600">
                  Now that your tenants are a part of your group, you can begin managing your properties. You can link
                  your bank account to get paid, start live chat with your tenants, and more.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-slate-900" id="pricing">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8  pb-16 text-center align-middle py-10 sm:py-24">
          <h4 class="text-md lg:text-lg font-semibold mb-5 lg:mb-2 text-blue-600">PRICING</h4>
          <h2 class="text-4xl lg:text-5xl font-semibold mb-5 lg:mb-2 text-white">
            <span class="relative whitespace-nowrap">
              <span class="relative">Cheaper </span>
            </span>
            than the highstreet.
          </h2>
          <p class="mt-4 text-lg text-slate-400">
            The size of your portfolio doesn't matter - we cover most cases under one single price.
          </p>
          <div className="flex flex-col md:flex-row justify-center">
            <section class="mt-12 flex flex-col rounded-xl px-6 sm:px-8 order-first bg-white py-8 lg:order-none w-full md:w-1/3 text-blue-600 relative overflow-hidden">
              <div className="absolute top-0 left-0 bg-blue-600 w-full py-2">
                <p className="text-white font-semibold">Your first month is free</p>
              </div>
              <h3 class="order-first font-display text-lg text-slate-900 mt-4">Per Month</h3>
              <p class="font-display text-5xl font-light tracking-tight border-b pb-5">£29.99</p>
              <p class="mt-5 text-base text-gray-900">Perfect for most landlords</p>
              <ul role="list" class="order-last mt-5 flex flex-col gap-y-3 text-sm text-slate-900">
                <li class="flex">
                  <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-blue-600">
                    <path
                      d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
                      stroke-width="0"
                    ></path>
                    <circle
                      cx="12"
                      cy="12"
                      r="8.25"
                      fill="none"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></circle>
                  </svg>
                  <span class="ml-4">Manage 8 seperate properties</span>
                </li>
                <li class="flex">
                  <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-blue-600">
                    <path
                      d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
                      stroke-width="0"
                    ></path>
                    <circle
                      cx="12"
                      cy="12"
                      r="8.25"
                      fill="none"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></circle>
                  </svg>
                  <span class="ml-4">Rent collection system</span>
                </li>
                <li class="flex">
                  <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-blue-600">
                    <path
                      d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
                      stroke-width="0"
                    ></path>
                    <circle
                      cx="12"
                      cy="12"
                      r="8.25"
                      fill="none"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></circle>
                  </svg>
                  <span class="ml-4">Tenant to landlord live chat</span>
                </li>
                <li class="flex">
                  <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-blue-600">
                    <path
                      d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
                      stroke-width="0"
                    ></path>
                    <circle
                      cx="12"
                      cy="12"
                      r="8.25"
                      fill="none"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></circle>
                  </svg>
                  <span class="ml-4">Access to paper document scanner</span>
                </li>
                <li class="flex">
                  <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-blue-600">
                    <path
                      d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
                      stroke-width="0"
                    ></path>
                    <circle
                      cx="12"
                      cy="12"
                      r="8.25"
                      fill="none"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></circle>
                  </svg>
                  <span class="ml-4">Unlimited cloud storage for documents</span>
                </li>
                <li class="flex">
                  <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-blue-600">
                    <path
                      d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
                      stroke-width="0"
                    ></path>
                    <circle
                      cx="12"
                      cy="12"
                      r="8.25"
                      fill="none"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></circle>
                  </svg>
                  <span class="ml-4">Instant push notification service for tenants</span>
                </li>
                <li class="flex">
                  <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-blue-600">
                    <path
                      d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
                      stroke-width="0"
                    ></path>
                    <circle
                      cx="12"
                      cy="12"
                      r="8.25"
                      fill="none"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></circle>
                  </svg>
                  <span class="ml-4">Complete access to the Acreetr toolbox</span>
                </li>
              </ul>
              <a
                class="group inline-flex items-center justify-center rounded py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-blue-600 text-white hover:bg-blue-50 active:bg-blue-200 active:text-slate-600 focus-visible:outline-white mt-5"
                aria-label="Get started with the Small business plan for $15"
                href="/download"
              >
                Get started
              </a>
            </section>
            <section class="ml-0 md:ml-6 mt-12  flex-col  order-second bg-slate lg:order-none mb-6 w-full md:w-1/3 text-blue-600 relative">
              <div className="bg-gradient-to-r from-[#004cff] to-[#2563eb] h-1/2 block px-6 sm:px-8 py-3 w-full rounded-xl">
                <h3 class="order-first font-display text-lg text-white text-white">Get in touch</h3>
                <p class="font-display text-5xl font-light tracking-tight border-b-blue-500 pb-3 text-white mb-3">
                  Enterprise
                </p>
                <p class="text-base text-white mb-3">For landlords managing more than 8 properties</p>

                <a
                  class="lg:mb-0 mb-3 w-full group inline-flex ring-1 items-center justify-center rounded py-2 px-4 text-sm focus:outline-none ring-blue-700 bg-white text-blue-500 hover:ring-slate-500 active:ring-slate-700 active:text-slate-400 focus-visible:outline-white mt-5"
                  aria-label="Get started with the Small business plan for $15"
                  href="/download"
                >
                  Contact us
                </a>
              </div>
              <div className="mt-6 bg-gradient-to-r from-[#004cff] to-[#2563eb] block px-6 sm:px-8 py-4 w-full rounded-xl h-1/2 relative overflow-hidden">
                <div className="z-10 text-white flex flex-col items-center justify-center relative">
                  <h1 className="text-4xl font-bold text-white underline">Toolbox</h1>
                  <p className="mt-3 text-white">
                    Access the entire aresenal to save you time. Generate documents, perform inventory checks,
                    distribute health & saftey certificates and more from the click of a button.
                  </p>
                  <a
                    class="lg:mb-0 mb-3 w-full group inline-flex ring-1 items-center justify-center rounded py-2 px-4 text-sm focus:outline-none ring-blue-700 bg-blue-600 text-white hover:ring-slate-500 active:ring-slate-700 active:text-slate-400 focus-visible:outline-white mt-5"
                    aria-label="Get started with the Small business plan for $15"
                    href="/faq"
                  >
                    Find out more
                  </a>
                </div>
                <img
                  className="absolute w-full h-full left-0 top-0"
                  alt=""
                  src={require("../assets/image/backdrop.jpg")}
                />
                <div className="absolute w-full h-full left-0 top-0 bg-slate-800 opacity-90" />
              </div>
            </section>
          </div>
        </div>
      </section>

      <section className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8  pb-16 text-center align-middle py-10 sm:py-24">
        <h3 className="text-4xl lg:text-5xl font-semibold mb-5 lg:mb-2 text-slate-900">
          Get to understand the property market
        </h3>
        <p className="mb-16 text-center text-slate-600 text-lg max-w-xl mx-auto">
          Read our carefully crafted articles to help you grow your knowlege
        </p>

        <div className="flex flex-col md:flex-row justify-between">
          <section
            className="mt-8 md:mt-0 cursor-pointer"
            onClick={() => (window.location = "/blog/top-7-tenant-communication-tips-for-landlords")}
          >
            <div className="relative">
              <img className="rounded-lg" src={require("../assets/image/blog/1.jpg")} alt="thubmnail" />
              <div className="absolute top-2 right-2 bg-blue-600 px-3 py-1 rounded-full text-white font-light">
                <p>3 minute read</p>
              </div>
            </div>
            <h3 className="py-3 font-bold text-xl text-left">Top 7 Tenant Communication Tips for Landlords</h3>
            <div className="flex flex-row justify-start items-center">
              <img
                className="w-9 h-9 rounded-full mr-3"
                src={require("../assets/image/blog/author-vishwa.png")}
                alt="vishwa-avatar"
              />
              <p className="text-gray-500">
                By <span className="text-blue-600">Vishwa Jeyam</span>
              </p>
            </div>
          </section>
          <section
            onClick={() => (window.location = "/blog/how-to-manage-rental-properties-a-simple-step-by-step-guide")}
            className="mx-0 mt-8 md:mx-16 md:mt-0 cursor-pointer"
          >
            <div className="relative">
              <img className="rounded-lg" src={require("../assets/image/blog/2.jpg")} alt="thubmnail" />
              <div className="absolute top-2 right-2 bg-blue-600 px-3 py-1 rounded-full text-white font-light">
                <p>9 minute read</p>
              </div>
            </div>
            <h3 className="py-3 font-bold text-xl text-left">
              How To Manage Rental Properties: A Simple Step-By-Step Guide
            </h3>
            <div className="flex flex-row justify-start items-center">
              <img
                className="w-9 h-9 rounded-full mr-3"
                src={require("../assets/image/blog/author-vishwa.png")}
                alt="vishwa-avatar"
              />
              <p className="text-gray-500">
                By <span className="text-blue-600">Vishwa Jeyam</span>
              </p>
            </div>
          </section>
          <section
            onClick={() => (window.location = "/blog/12-ways-to-add-value-to-your-rental-property")}
            className="mt-8 md:mt-0 cursor-pointer"
          >
            <div className="relative">
              <img className="rounded-lg" src={require("../assets/image/blog/3.jpg")} alt="thubmnail" />
              <div className="absolute top-2 right-2 bg-blue-600 px-3 py-1 rounded-full text-white font-light">
                <p>6 minute read</p>
              </div>
            </div>
            <h3 className="py-3 font-bold text-xl text-left">12 ways to add value to your rental property</h3>
            <div className="flex flex-row justify-start items-center">
              <img
                className="w-9 h-9 rounded-full mr-3"
                src={require("../assets/image/blog/author-vishwa.png")}
                alt="vishwa-avatar"
              />
              <p className="text-gray-500">
                By <span className="text-blue-600">Vishwa Jeyam</span>
              </p>
            </div>
          </section>
        </div>
      </section>

      <section className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-center align-middle pb-10">
        <div className="bg-gradient-to-r from-[#004cff] to-[#2563eb] w-full min-h-48 rounded p-10 text-white flex flex-col lg:flex-row justify-between items-center">
          <div className="mb-5 lg:m-0">
            <h2 className="text-center lg:text-left text-3xl lg:text-4xl font-semibold mb-3">
              Ready to manage your properties?
            </h2>
            <p className="text-center lg:text-left opacity-75 text-lg lg:text-xl">
              Simply download the app now available on both the Apple Appstore and Google Playstore
            </p>
          </div>
          <div
            onClick={() => (window.location = "/download")}
            className="bg-white px-6 py-3 rounded cursor-pointer w-9/12 lg:w-auto "
          >
            <p className="text-blue-600 ">Download Now</p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
